@charset "utf-8";

/* container */
.not-container{padding:10vh 0 15vh;width:80%;margin: auto;text-align: center;}
.not-container h2{font-size: 70px; padding: 10px 0;}
.not-container h3{font-size: 24px; font-weight: bold;}
.not-container .contents-sub-text{padding:20px 0 40px; font-size:15px;line-height: 1.4;}
.not-container .home-btn{display:inline-block; margin:auto; background-color:#be2a62; padding:1.5% 3%; transition: background-color .2s ease-in-out;}
.not-container .home-btn span{color:#fff; font-weight: bold; font-size:16px;}
.not-container .home-btn:hover{background-color:#701e8c;}
.not-container .contact-area{border:1px solid #e6e6e6; max-width:600px; width:100%; padding:20px 0; margin:40px auto 0; font-size: 13px;}
.not-container .contact-area dt{font-weight: bold; font-size: 15px;}


/* responsive */
@media (max-width: 768px) {
    .not-contents{ width: 80%;margin: auto; }
    .not-container h1{padding: 10px 0 0;}
    .not-container h2{font-size: 50px;}
    .not-container h3{font-size: 18px;word-break: keep-all;}
    .not-container .contents-sub-text{font-size:12px;word-break: keep-all;}
    .not-container .home-btn{padding:5% 9%;}
    .not-container .home-btn span{font-size:15px;}
    .not-container .contact-area{font-size: 11px;}
    .not-container .contact-area dt{font-size: 12px;}
    .not-container footer{font-size:11px;}
}